// src/pages/waiting_page.js
import React, { useState } from 'react';
import logo from '../img/menuscan_logo3bg.png';
import Api from '../lib/Api';

function WaitingPage() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    Api.AddWaitingUser(email);
    setSubmitted(true);
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-gray-800 text-gray-200 min-h-screen flex flex-col items-center justify-center">
      <div className="mb-6">
        <img src={logo} alt="Logo" className="h-24 md:h-40" />
      </div>
      <h1 className="text-3xl md:text-4xl font-bold mb-4">Już wkrótce!</h1>
      <p className="text-xl md:text-2xl mb-8 text-center px-4">
        Nasza aplikacja jest w trakcie tworzenia.
      </p>
      <p className="text-lg mb-6 text-center px-4">
        Zostaw swój adres e-mail, a powiadomimy Cię, gdy będzie dostępna!
      </p>
      {submitted ? (
        <p className="text-green-500 text-lg">
          Dziękujemy! Powiadomimy Cię, gdy aplikacja będzie dostępna.
        </p>
      ) : (
        <form onSubmit={handleSubmit} className="w-full max-w-sm">
          <div className="flex items-center border-b border-green-500 py-2">
            <input
              type="email"
              required
              placeholder="Wprowadź swój e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none bg-transparent border-none w-full text-gray-200 mr-3 py-1 px-2 leading-tight focus:outline-none placeholder-gray-400"
            />
            <button
              type="submit"
              className="flex-shrink-0 bg-green-500 hover:bg-green-600 text-white py-1 px-4 rounded"
            >
              Wyślij
            </button>
          </div>
        </form>
      )}
      <footer className="absolute bottom-4 text-gray-500 text-sm">
        &copy; {new Date().getFullYear()} MenuScan. Wszelkie prawa zastrzeżone.
      </footer>
    </div>
  );
}

export default WaitingPage;
