export default class Api {
    static api_url = 'https://menuscan.pl/api/v1';
    static x_api_key = 'test';

    // Metoda pomocnicza do ustawiania nagłówków
    static getHeaders(includeAuth = true) {
        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': this.x_api_key,
        };
        if (includeAuth) {
            headers['access_token'] = localStorage.getItem('access_token');
        }
        return headers;
    }
    static async AddWaitingUser(email) {
        const userData = {
            email
        };
        try {
            const response = await fetch(`${this.api_url}/prerelase/user`, {
                method: 'POST',
                headers: this.getHeaders(false),
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Błąd.');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Wystąpił problem z dodaniem użytkownika:', error);
            throw error;
        }
    }
}